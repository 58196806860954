import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useMemo, useEffect, useRef, DependencyList, useCallback } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import commonMsg from '@whitelabel/cp-shared/helpers/messages/commonMsg';
import { MAP_RC_LOCALE_TO_XC } from './constants';

/**
 * Single hook to get all the router related information
 */
interface IDefaultRouterParams {
  locale: string;
  [key: string]: string;
}
export const useTypedRouter = <T = IDefaultRouterParams>() => {
  const { locale, ...restParams } = useParams<IDefaultRouterParams>();
  const history = useHistory();
  const { search } = useLocation();
  const query = useMemo(
    () =>
      ({
        ...restParams,
        ...Object.fromEntries(new URLSearchParams(search)),
      } as T),
    [search, restParams],
  );
  // Convert RC locale to XC locale
  const APILocale = useMemo(() => MAP_RC_LOCALE_TO_XC[locale] || locale, [locale]);

  return { query, locale, APILocale, ...history };
};

export const useRegisterBrowserExitWarning = (
  callback: () => { isEnabled: boolean; message?: MessageDescriptor },
  deps: DependencyList,
) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  // use ref instead of state to avoid stale state problems
  const message = useRef<string>(formatMessage(commonMsg.defaultBrowserExitWarning));
  const enabled = useRef<boolean>(false);
  const isDebouncing = useRef<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { isEnabled, message: newMessage } = useMemo(callback, deps);
  message.current = formatMessage(newMessage ?? commonMsg.defaultBrowserExitWarning);

  useEffect(() => {
    if (!isDebouncing.current) {
      enabled.current = isEnabled;
      isDebouncing.current = true;
      setTimeout(() => {
        isDebouncing.current = false;
      }, 500);
    }
  }, [isEnabled]);

  const beforeUnloadHandler = useCallback((event: BeforeUnloadEvent) => {
    if (!enabled.current) return null;

    // custom message is no longer supported in modern browsers
    event.returnValue = message.current;
    return message.current;
  }, []);

  useEffect(() => {
    const handleRouteChangeRejection = (event: PromiseRejectionEvent) => {
      if (event.reason === 'routeChangeError') {
        event.preventDefault();
      }
    };
    window.addEventListener('beforeunload', beforeUnloadHandler);
    const unblock = history.block(() => {
      if (!enabled.current || window.confirm(message.current)) return undefined;

      return false;
    });

    window.addEventListener('unhandledrejection', handleRouteChangeRejection);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      unblock();
      window.removeEventListener('unhandledrejection', handleRouteChangeRejection);
    };
  }, [beforeUnloadHandler, history]);
};
