import React from 'react';
import { StyledBrand, StyledBrandLogo, StyledBrandTitle } from './styledBrand';

export interface IBrandProps {
  href?: string;
  title: string;
  logo: string;
  size?: 'md' | 'lg';
}

const Brand = ({ href, title, logo, size }: IBrandProps): JSX.Element => (
  <StyledBrand href={href}>
    <>
      <StyledBrandLogo src={logo} alt={title} size={size} data-test-id="NavBarBrand-logo" />
      <StyledBrandTitle data-test-id="NavBarBrand-title">{title}</StyledBrandTitle>
    </>
  </StyledBrand>
);

export default Brand;
