import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { rtkQueryErrorLogger } from '@whitelabel/cp-shared/store/middlewares/ApiErrorLogger';
import { xcmsAPI } from '@whitelabel/xcover-shared/store/services/xcms';
import { claimsPortalAPI } from '@whitelabel/cp-shared/store/claimsPortalAPI';
import { rentalcoverAPI } from '@whitelabel/cp-shared/store/rentalcoverAPI';
import { customerReducer } from './slices/customer';

export const store = configureStore({
  reducer: {
    [claimsPortalAPI.reducerPath]: claimsPortalAPI.reducer,
    [xcmsAPI.reducerPath]: xcmsAPI.reducer,
    [rentalcoverAPI.reducerPath]: rentalcoverAPI.reducer,
    customer: customerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(claimsPortalAPI.middleware)
      .concat(rentalcoverAPI.middleware)
      .concat(xcmsAPI.middleware)
      .concat(rtkQueryErrorLogger),
});

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
