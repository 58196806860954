import { getRCAuthToken } from '@whitelabel/cp-shared/helpers/auth';
import { claimsPortalAPI } from '@whitelabel/cp-shared/store/claimsPortalAPI';
import { parseJwt } from '@whitelabel/rentalcover-shared/helpers/utility';
import { IAuth } from '@whitelabel/rentalcover-shared/helpers/types';
import { getCPAPIHost } from '@whitelabel/cp-shared/helpers/api';
import { setAuthToken } from '@whitelabel/rentalcover-shared/helpers/auth';

export const authAPI = claimsPortalAPI.injectEndpoints({
  endpoints: (builder) => ({
    getTokenStatus: builder.query<string, void>({
      queryFn: async () => {
        /**
         * Get the status of the RC token
         * - expired: if the token exists but is expired
         * - valid: if the token is valid
         * - none: if the token does not exist
         */
        const token = getRCAuthToken();
        if (!token) return { data: 'none' };
        const jwt = parseJwt(token);
        const jwtExpiryDate = new Date(jwt.exp * 1000);
        const isExpired = jwtExpiryDate.getTime() < Date.now();

        return { data: isExpired ? 'expired' : 'valid' };
      },
      providesTags: ['RCToken'],
    }),
    getRefreshToken: builder.mutation<IAuth, void>({
      query: () => ({
        url: `${getCPAPIHost()}/auth/refresh`,
        headers: { 'Cache-Control': 'no-store,no-cache' },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const {
          data: { jwt },
        } = await queryFulfilled;
        setAuthToken(jwt);
        // manual invalidate tags after setting the new token
        dispatch(claimsPortalAPI.util.invalidateTags(['RCToken']));
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetTokenStatusQuery, useGetRefreshTokenMutation } = authAPI;
