import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { StyledSpecialButton } from '@whitelabel/rentalcover-shared/styled/StyledSpecialButton';

interface IGhostButtonLinkProps {
  href: string;
}

const GhostButton = ({ href, children }: PropsWithChildren<IGhostButtonLinkProps>): JSX.Element => {
  const { locale } = useIntl();
  const hrefWithLocale = `/${locale}${href}`;

  return <StyledSpecialButton href={hrefWithLocale}>{children}</StyledSpecialButton>;
};

export default GhostButton;
