import { EN_LOCALE_TEXT } from '@whitelabel/rentalcover-shared/helpers/constants';
import languagesRC from '@whitelabel/rentalcover-shared/helpers/constants/languagesRC.json';

function getSelectedLocale(browserLanguage: string, languageArray: string[]) {
  const translatedLanguage =
    languageArray.find((lang) => lang === browserLanguage) ||
    languageArray.find((lang) => browserLanguage.startsWith(lang));
  return translatedLanguage || EN_LOCALE_TEXT;
}

function getBrowserLanguage() {
  return (navigator.languages && navigator.languages[0]) || navigator.language || (navigator as any).userLanguage;
}

/**
 * Detects the user's language and redirects them to the correct locale if necessary
 * @returns locale
 */
export function detectLanguage() {
  const { pathname: urlPathname } = window.location;
  const pathLanguage = urlPathname.split('/')[1];

  const availableLocales = Object.keys(languagesRC);
  if (!pathLanguage || !availableLocales.includes(pathLanguage)) {
    const translatedLanguage = getSelectedLocale(getBrowserLanguage(), availableLocales);
    let { href } = window.location;
    const slashIndex = href.indexOf('/', 8);

    if (availableLocales.includes(pathLanguage)) {
      href = href.replace(`/${pathLanguage}`, '');
    }
    let newURL = `${href.slice(0, slashIndex)}/${translatedLanguage}${href.slice(slashIndex)}`;
    newURL = newURL.replace(/(\/)+$/, '');
    window.location.replace(newURL);
    return null;
  }

  return pathLanguage;
}
