import React, { ReactNode, useMemo } from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { useFetchMessagesQuery } from '@whitelabel/xcover-shared/store/services/xcms/messages';
import { useFetchRCMessagesQuery } from '@whitelabel/cp-shared/store/rentalcoverAPI/messages';
import { StyledLoading } from '@whitelabel/rentalcover-shared/styled';
import { IntlGlobalProvider } from '@whitelabel/cp-shared/helpers/intlSingletonService';
import { locales } from '@whitelabel/rentalcover-shared/helpers/site';
import ErrorPage from '../ErrorPage';
import { useTypedRouter } from '../../helpers/hooks';

const cache = createIntlCache();

const IntlConfigProvider = ({ children }: { children: ReactNode }) => {
  const { APILocale, locale } = useTypedRouter();

  const {
    currentData: XCmessages,
    isFetching: isFetchingXCMessages,
    isError: isFetchXCMessagesError,
  } = useFetchMessagesQuery({ locale: APILocale, reqInit: {} });

  const {
    currentData: RCmessages,
    isFetching: isFetchingRCMessages,
    isError: isFetchRCMessagesError,
  } = useFetchRCMessagesQuery({ locale });

  const intl = useMemo(() => {
    const { isRTL } = locales[locale];
    const messages = { ...RCmessages, ...XCmessages };
    const modifiedIntl = createIntl({ locale, messages }, cache);
    modifiedIntl.bidi = isRTL;
    return modifiedIntl;
  }, [locale, XCmessages, RCmessages]);

  const renderComponent = () => {
    if (isFetchingXCMessages || isFetchingRCMessages) {
      return <StyledLoading />;
    }

    if (isFetchXCMessagesError || isFetchRCMessagesError) {
      return <ErrorPage />;
    }
    return children;
  };

  return (
    <RawIntlProvider value={intl}>
      <IntlGlobalProvider>{renderComponent()}</IntlGlobalProvider>
    </RawIntlProvider>
  );
};

export default IntlConfigProvider;
