import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import rentalcoverSharedMessages from '@whitelabel/rentalcover-shared/helpers/messages/commonMsg';
import { StyledHeroBlock } from '@whitelabel/rentalcover-shared/styled/StyledErrorPage';
import RentalCoverLayout from '../../containers/layout/RentalCoverLayout';

interface IErrorPageProps {
  isNoMatch?: boolean;
}

const ErrorPage = ({ isNoMatch = false }: IErrorPageProps): JSX.Element => {
  const { pathname } = useLocation();
  const { formatMessage, locale } = useIntl();
  const title = formatMessage(rentalcoverSharedMessages.generalErrorTitle);
  const leadText = formatMessage(rentalcoverSharedMessages.generalErrorText);

  const singleButtonBlock = [
    {
      type: 'buttons',
      value: {
        alignment: 'left',
        buttons: [
          {
            link: `/`,
            text: formatMessage(rentalcoverSharedMessages.generalErrorButtonText),
            variant: 'tertiary',
            buttonType: 'tertiary',
            icon: 'arrow-left',
            onClick: () => {
              // upon clicking on back to home button do a hard refresh so user goes to old app
              window.location.href = `/${locale}/`;
            },
          },
        ],
      },
      id: '3c8ce8e9-0ff5-4e88-beb5-1ef737dbceb3',
    },
  ];

  useEffect(() => {
    if (isNoMatch) {
      const err = new Error();
      err.name = 'URL Not Found';
      err.message = `URL: ${pathname} cannot be found`;
      captureExceptionWithFullStory(err);
    }
  }, [isNoMatch, pathname]);

  return (
    <RentalCoverLayout showDisclaimer="collapsed" hideCustomer>
      <StyledHeroBlock
        className="error-page-title"
        id="errorPageHeroBlock"
        title={title}
        leadText={leadText}
        backgroundImage={{ file: `${import.meta.env.VITE_PUBLIC_PATH}/rentalcover-error-image.png` }}
        ctaOptions={singleButtonBlock}
      />
    </RentalCoverLayout>
  );
};

export default ErrorPage;
