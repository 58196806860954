import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';

const API_HOST = process.env.NEXT_PUBLIC_CMS_API_HOST;

export const xcmsAPI = createApi({
  reducerPath: 'xcmsAPI',
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
    return undefined;
  },
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/`,
  }),
  endpoints: () => ({}),
});
