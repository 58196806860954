import React, { useState, useEffect } from 'react';
import { ReactComponent as UnknownFlag } from '@whitelabel/media/icons/flags/selected-flag.svg';
import { isUSEnglish } from '@whitelabel/helpers/site';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import { IError } from '@whitelabel/helpers/types';

import { EN_US_LOCALE_TEXT } from '../../helpers/constants';

interface IFlagMapProps {
  locale: string;
}

// Mapping from locale to the path
const flagMap: Record<string, string> = {
  az: 'az',
  id: 'id',
  ms: 'ms',
  ca: 'ca',
  cs: 'cs',
  da: 'da',
  de: 'de',
  et: 'et',
  el: 'el',
  es: 'es',
  'es-mx': 'es-mx',
  fil: 'fil',
  fr: 'fr',
  hr: 'hr',
  is: 'is',
  it: 'it',
  sw: 'sw',
  lv: 'lv',
  lt: 'lt',
  hu: 'hu',
  mt: 'mt',
  nl: 'nl',
  no: 'no',
  pl: 'pl',
  pt: 'pt',
  'pt-br': 'pt-br',
  ro: 'ro',
  sk: 'sk',
  sl: 'sl',
  fi: 'fi',
  sv: 'sv',
  vi: 'vi',
  tr: 'tr',
  bg: 'bg',
  ru: 'ru',
  sr: 'sr',
  uk: 'uk',
  he: 'he',
  ar: 'ar',
  th: 'th',
  ka: 'ka',
  gl: 'gl',
  ja: 'ja',
  ko: 'ko',

  eu: 'eu',

  'zh-hans': 'zh',
  'zh-hant': 'zh',
  en: 'gb',
  'en-us': 'us',
  'fr-ca': 'canada',
};

const FlagIcon = ({ locale }: IFlagMapProps) => {
  const [imgPath, setImgPath] = useState<string>();

  useEffect(() => {
    const importIcon = async () => {
      try {
        const flagXCode = isUSEnglish(locale) ? EN_US_LOCALE_TEXT : locale;
        const name = flagMap[flagXCode];

        if (!name) return;
        const { default: path } = await import(`@whitelabel/media/icons/flags/localeFlags/${name}.svg`);
        setImgPath(path);
      } catch (err) {
        captureExceptionWithFullStory(err as IError);
        console.error(err);
      }
    };
    void importIcon();
  }, [locale]);

  return imgPath ? (
    <img src={imgPath} alt={`${locale} flg`} width="25" height="16" className="flag-image flag-border" />
  ) : (
    <UnknownFlag />
  );
};

export default FlagIcon;
