import styled, { Theme } from 'styled-components';
import { Container } from 'reactstrap';
import { themeColor } from '@whitelabel/helpers/style/colors';

export const StyledSecondaryNavBar = styled.nav<{ $themeColor?: keyof Theme['themeColors'] }>`
  padding-block: 1rem;
  background-color: ${({ $themeColor }) => themeColor($themeColor ?? 'tertiaryBg')};
`;

export const StyledContainer = styled(Container)`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  height: 100%;
  column-gap: 2rem;
`;
