import styled from 'styled-components';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { fontWeight } from '@whitelabel/helpers/style/type';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

export const StyledCustomerDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  > * {
    margin-bottom: 0.25rem;

    ${mediaBreakpointUp('md')`
      margin-bottom: 0.5rem;
    `}
  }

  .customer-name {
    color: ${themeColor('heading')};

    font-weight: ${fontWeight('black')};
    font-size: 1.125rem;
  }

  .customer-email {
    color: ${themeColor('subheading')};
  }

  .customer-credit {
    font-weight: ${fontWeight('bold')};
    padding: 0 0.5rem;
    width: fit-content;
    background-color: #e6f6f3;
    border-radius: 0.5rem;
    color: #008068;
  }
`;
