import styled from 'styled-components';
import { DropdownItem } from 'reactstrap';
import { color } from '@whitelabel/helpers/style/colors';
import {
  BaseStyledDropdown,
  BaseStyledDropdownItem,
  BaseStyledDropdownMenu,
  BaseStyledDropdownToggle,
} from './StyledBaseDropdown';

export const StyledDropdownToggle = BaseStyledDropdownToggle;
export const StyledDropdown = BaseStyledDropdown;
export const StyledDropdownMenu = BaseStyledDropdownMenu;
export const StyledDropdownItem = BaseStyledDropdownItem;

export const StyledDropdownItemDivider = styled(DropdownItem).attrs(() => ({ divider: true }))`
  margin: 0 0 0.5rem;
  border-color: ${color('paleGrayBlue')};
`;

export const StyledDropdownItemIcon = styled.svg.attrs(() => ({ 'aria-hidden': 'true' }))`
  display: block;
  margin-inline-end: 0.5rem;
`;
