import React from 'react';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import ErrorPage from '../components/ErrorPage';

interface IErrorBoundaryProps {
  children: React.ReactElement;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    this.setState({ error });
    captureExceptionWithFullStory(error);
  }

  render() {
    const { error, hasError } = this.state;
    const { children } = this.props;

    if (error || hasError) return <ErrorPage />;
    return children;
  }
}

export default ErrorBoundary;
