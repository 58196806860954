import { defineMessages } from 'react-intl';

export default defineMessages({
  readMoreText: {
    id: 'generic.button.readMore',
    defaultMessage: 'Read More',
  },
  readLessText: {
    id: 'generic.button.readLess',
    defaultMessage: 'Read Less',
  },
});
