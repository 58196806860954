import styled from 'styled-components';
import Divider from '@whitelabel/component-library/src/styled/StyledDivider';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

const StyledDivider = styled(Divider)`
  background-color: transparent;
  margin: 1rem 0;

  ${mediaBreakpointUp('sm')`
    margin: 2rem 0;
  `}
`;

export default StyledDivider;
