import { colors } from '@whitelabel/global-theme/colors';

const button = {
  textTransform: 'uppercase',
  fontWeight: 900,
  width: 'auto',
  borderRadii: {
    base: '3px',
    sm: '2px',
  },
  color: colors.white,
};

export default button;
