import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints
export const rentalcoverAPI = createApi({
  reducerPath: 'rentalcoverAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.VITE_RC_API_HOST,
  }),
  endpoints: () => ({}),
});
