import styled from 'styled-components';
import Modal from '@whitelabel/component-library/src/components/Modal';
import { lineHeight } from '@whitelabel/helpers/style/type';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { color } from '@whitelabel/helpers/style/colors';

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 1rem;

    ${mediaBreakpointUp('md')`
      padding: 4rem;
    `}

    .modal-header > .btn-close {
      right: unset;
      inset-inline-end: 1rem;
    }
  }

  .modal-heading {
    width: calc(100% - 2rem);
    line-height: ${lineHeight('headings')};

    ${mediaBreakpointUp('sm')`
      width: 100%;
    `}
  }

  && .modal-title {
    text-transform: none;
    color: ${color('prussianBlue')};
  }

  .modal-button-block {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: space-between;

    ${mediaBreakpointUp('sm')`
      justify-content: flex-start;
    `}

    .btn {
      padding: 1rem 1.5rem;
      width: 100%;

      :not(:last-child) {
        margin-bottom: 1rem;
      }

      ${mediaBreakpointUp('sm')`
        width: auto;
        margin-inline-start: 2rem;
  
        :not(:last-child) {
          margin: 0;
        }
      `}
    }
  }
`;

export default StyledModal;
