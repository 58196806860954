// theme
const primary = '#ff6105'; // primary
const trinidad = '#E45400';
const dodgerBlue = '#0096FF'; // secondary
const brightOrange = '#FF8C49';
const aquamarineBlue = '#73e6d2';
const cerulean = '#00beff';
const blue = '#0057ff'; // info
const malibu = '#66D8FF'; // selection
const scarlet = '#ff3200'; // danger
const persianGreen = '#00a082'; // success
const tango = '#eb711a'; // warning
const indigo = '#6610f2';
const purple = '#6f42c1';
const pink = '#e83e8c';
const white = '#fff';
const black = '#000';
const dustyGray = '#999';
const soapstone = '#fff9f7';
const blackSqueeze = '#f7fcfb';
const fern = '#66bc74';
const cadetBlue = '#acb5bf';
const darkEclipse = '#0d1b3f';
const cyanBlue = '#00327c';
const coral = '#FFC7A7';
const globalSuccess = '#008068';
const lightgreen = '#91f127';
const lightenedSuccess = '#E6F6F3';
const lightenedInfo = '#E6EEFF';
const lightenedDanger = '#FFEBE6';
const aliceblue = '#E8F1F8';
const oliveDrab = '#3C5F80';
const prussianBlue = '#002B52';
const paleGrayBlue = '#D4E2EB';

// grays;
const alabaster = '#fbfbfb';
const wildSand = '#f6f6f6';
const catskillWhite = '#f0f4f8';
const mercury = '#e0e4e8';
const ghost = '#c5cad1';
const rollingStone = '#73777c'; // Anchor/visited
const doveGray = '#717171';
const mineShaft = '#2d2d2d';

const tertiaryDisabled = '#A3B8C6';

// component colours
export const colors = {
  blue,
  indigo,
  purple,
  pink,
  primary,
  persianGreen,
  aquamarineBlue,
  cerulean,
  white,
  black,
  catskillWhite,
  mercury,
  dustyGray,
  soapstone,
  blackSqueeze,
  fern,
  cadetBlue,
  darkEclipse,
  dodgerBlue,
  cyanBlue,
  coral,
  lightgreen,
  aliceblue,
  rollingStone,
  doveGray,
  globalSuccess,
  lightenedSuccess,
  lightenedInfo,
  lightenedDanger,
  oliveDrab,
  prussianBlue,
  paleGrayBlue,
};

export const grays = {
  100: alabaster,
  200: wildSand,
  300: catskillWhite,
  400: mercury,
  500: ghost,
  600: doveGray,
  700: mineShaft,
};

const bgLight = '#F8FCFF';

export const themeColors = {
  primary,
  secondary: dodgerBlue,
  tertiary: darkEclipse,
  anchor: 'inherit',
  selection: malibu,
  info: blue,
  danger: scarlet,
  success: persianGreen,
  globalSuccess,
  warning: tango,
  header: mineShaft,
  body: grays[600],
  completed: cadetBlue,
  light: grays[200],
  dark: grays[700],
  primaryBg: white,
  secondaryBg: wildSand,
  tertiaryBg: aliceblue,
  subtext: grays[600],
  disabled: grays[600],
  subheading: oliveDrab,
  heading: prussianBlue,
  bgLight,
};

export const buttonColors = {
  primary: {
    bg: {
      default: primary,
      hover: brightOrange,
      active: trinidad,
      focus: primary,
      disabled: catskillWhite,
      focusVisible: primary,
    },
    label: {
      default: white,
      hover: white,
      active: white,
      focus: white,
      disabled: ghost,
      focusVisible: white,
    },
    outline: {
      default: 'transparent',
      hover: 'transparent',
      active: 'transparent',
      disabled: 'transparent',
    },
    icon: {
      default: 'inherit',
      hover: 'inherit',
      active: 'inherit',
      disabled: 'inherit',
      focusVisible: 'inherit',
    },
  },

  secondary: {
    bg: {
      default: white,
      hover: white,
      active: white,
      disabled: white,
    },
    label: {
      default: primary,
      hover: brightOrange,
      focus: primary,
      active: mineShaft,
      disabled: ghost,
      focusVisible: mineShaft,
    },
    outline: {
      default: primary,
      hover: brightOrange,
      active: mineShaft,
      disabled: catskillWhite,
    },
    icon: {
      default: primary,
      hover: brightOrange,
      focus: primary,
      active: mineShaft,
      disabled: ghost,
      focusVisible: mineShaft,
    },
  },

  tertiary: {
    bg: {
      default: 'transparent',
      hover: 'transparent',
      active: 'transparent',
      focus: 'transparent',
      disabled: 'transparent',
      focusVisible: 'transparent',
    },
    label: {
      default: oliveDrab,
      hover: prussianBlue,
      active: prussianBlue,
      focus: oliveDrab,
      disabled: tertiaryDisabled,
      dark: white,
      focusVisible: prussianBlue,
    },
    outline: {
      default: 'transparent',
      hover: 'transparent',
      active: 'transparent',
      focus: prussianBlue,
      disabled: 'transparent',
    },
    icon: {
      default: oliveDrab,
      hover: prussianBlue,
      active: prussianBlue,
      focus: oliveDrab,
      disabled: tertiaryDisabled,
      dark: primary,
      focusVisible: prussianBlue,
    },
  },

  primaryDark: {
    bg: {
      default: prussianBlue,
      hover: prussianBlue,
      focus: prussianBlue,
      active: prussianBlue,
      disabled: catskillWhite,
      focusVisible: prussianBlue,
    },
    label: {
      default: white,
      hover: white,
      focus: white,
      active: white,
      disabled: white,
      focusVisible: white,
    },
    outline: {
      default: 'transparent',
      hover: 'transparent',
      focus: 'transparent',
      active: 'transparent',
      disabled: 'transparent',
    },
    icon: {
      default: 'inherit',
      hover: 'inherit',
      focus: 'inherit',
      active: 'inherit',
      disabled: 'inherit',
      focusVisible: 'inherit',
    },
  },
};

export const contentColors = {
  listLabel: '#73777C',
  listValue: '#2D2D2D',
};

export const backgroundColors = {
  border: '#1AAA8F',
  fill: '#F2FAF8',
};
