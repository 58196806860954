import { Auth } from '@aws-amplify/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import { CP_ROOT } from '../../helpers/constants';
import { getRCAuthToken } from '../../helpers/auth';

// Define a service using a base URL and expected endpoints
export const claimsPortalAPI = createApi({
  reducerPath: 'claimsPortalAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: async (headers, api) => {
      if (CP_ROOT === 'xcover') {
        try {
          const session = await Auth.currentSession();
          const jwtToken = session.getAccessToken().getJwtToken();
          if (jwtToken) {
            headers.set('Authorization', `Bearer ${jwtToken}`);
          } else {
            throw new Error(`No token attached to request ${api.endpoint}`);
          }
        } catch (error: any) {
          captureExceptionWithFullStory(new Error(error));
        }
      }
      if (CP_ROOT === 'rentalcover') {
        try {
          const token = getRCAuthToken();
          if (token) {
            headers.set('Authorization', `Bearer ${token}`);
            headers.set('X-CP-ROOT', CP_ROOT);
          } else {
            throw new Error(`No token attached to request ${api.endpoint}`);
          }
        } catch (error: any) {
          captureExceptionWithFullStory(new Error(error));
        }
      }
      return headers;
    },
  }),
  tagTypes: ['details', 'RCToken'],
  endpoints: () => ({}),
});
