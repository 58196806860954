import { ITranslation } from '@whitelabel/rentalcover-shared/helpers/types';
import { getModifiedTranslations } from '@whitelabel/rentalcover-shared/helpers/translations';
import { rentalcoverAPI } from '.';

const messagesAPI = rentalcoverAPI.injectEndpoints({
  endpoints: (builder) => ({
    fetchRCMessages: builder.query<Record<string, string>, { locale: string }>({
      query: ({ locale }) => `translations/${locale}/page.payment.v2`,
      transformResponse: (response: Record<'data', ITranslation[]>) => getModifiedTranslations(response.data),
    }),
  }),
  overrideExisting: false,
});

export const { useFetchRCMessagesQuery } = messagesAPI;
