import React from 'react';
import { useIntl } from 'react-intl';
import CustomerDetails from '@whitelabel/rentalcover-shared/components/CustomerDetails';
import { ICustomer } from '@whitelabel/rentalcover-shared/helpers/types';
import { getFullnameFromUser } from '@whitelabel/rentalcover-shared/helpers/auth';
import {
  StyledDropdown,
  StyledDropdownItemDivider,
  StyledDropdownMenu,
  StyledDropdownToggle,
} from '@whitelabel/rentalcover-shared/styled/StyledCustomerDropdown';
import { ReactComponent as IconCustomer } from '@whitelabel/rentalcover-shared/media/images/icon-customer.svg';
import { profileLink, protectionLink } from '@whitelabel/rentalcover-shared/helpers/navLinks';
import NavItem from '../NavItem';
import DropdownItem from './DropdownItem';

interface ICustomerDropdownProps {
  customer: ICustomer;
}

const CustomerDropdown = ({ customer }: ICustomerDropdownProps): JSX.Element => {
  const intl = useIntl();
  const fullName = getFullnameFromUser(customer) || '';

  return (
    <StyledDropdown nav inNavbar end data-test-id="CustomerDropdown">
      <StyledDropdownToggle nav data-test-id="CustomerDropdown-toggle">
        <NavItem icon={IconCustomer} name={fullName} />
      </StyledDropdownToggle>

      <StyledDropdownMenu $rtl={intl.bidi} direction="left" end data-test-id="CustomerDropdown-menu">
        <CustomerDetails customer={customer} />

        <StyledDropdownItemDivider />
        <DropdownItem {...protectionLink(intl)} />
        <DropdownItem {...profileLink(intl)} />
      </StyledDropdownMenu>
    </StyledDropdown>
  );
};

export default CustomerDropdown;
