import React, { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as IconChevronUp } from '@whitelabel/media/icons/small/chevron-up.svg';
import { ReactComponent as IconChevronDown } from '@whitelabel/media/icons/small/chevron-down.svg';
import { StyledCollapseButton, StyledCollapseText, StyledCollapse } from './styledCollapsibleText';
import messages from './messages';

interface ICollapsibleText {
  firstElement: ReactElement;
  restElement: ReactElement;
}

const CollapsibleText = ({ firstElement: shownElement, restElement: hiddenElement }: ICollapsibleText) => {
  const [collapse, setCollapse] = useState(true);
  const { formatMessage } = useIntl();

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <>
      {shownElement}
      <StyledCollapse isOpen={!collapse}>{hiddenElement}</StyledCollapse>
      <StyledCollapseButton buttonType="tertiary" onClick={toggleCollapse}>
        {collapse ? <IconChevronDown /> : <IconChevronUp />}
        <StyledCollapseText>
          {formatMessage(collapse ? messages.readMoreText : messages.readLessText)}
        </StyledCollapseText>
      </StyledCollapseButton>
    </>
  );
};

export default CollapsibleText;
