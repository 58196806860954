import { mergeAll } from 'lodash/fp';
import originalTheme from '@whitelabel/global-theme';
import { buttonColors, colors, themeColors } from './colors';
import button from './button';
import nav from './nav';
import { fontFamilies } from './fonts';

export const rentalcoverTheme = {
  colors,
  themeColors,
  buttonColors,
  button,
  nav,
  fontFamilies,
};

// eslint-disable-next-line lodash-fp/no-extraneous-function-wrapping
function overrideTheme(config: typeof rentalcoverTheme) {
  return mergeAll([{}, originalTheme, config]);
}

export const theme = overrideTheme({
  ...rentalcoverTheme,
});
