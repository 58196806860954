import React from 'react';
import { Container } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import Button from '@whitelabel/component-library/src/components/Button';
import {
  StyledCustomerButton,
  StyledNav,
  StyledNavBarToggler,
  StyledNavButtonGroups,
  StyledContainer,
} from '@whitelabel/rentalcover-shared/styled/StyledPrimaryNavBar';
import { SITE_NAME } from '@whitelabel/rentalcover-shared/helpers/constants';
import Brand from '@whitelabel/rentalcover-shared/components/Brand';
import { Media } from '@whitelabel/helpers/media';
import { StyledLoading } from '@whitelabel/rentalcover-shared/styled';
import rentalcoverSharedMessages from '@whitelabel/rentalcover-shared/helpers/messages/commonMsg';
import rentalcoverLogoDark from '@whitelabel/rentalcover-shared/media/images/rentalcover-logo-dark.svg';
import { useAppSelector } from '../../../../store';
import { selectCurrentCustomer } from '../../../../store/slices/customer';
import { HelpButton, LocaleButton } from './NavButtons';
import CustomerDropdown from './CustomerDropdown';

const brand = {
  title: SITE_NAME,
  logo: rentalcoverLogoDark,
  link: true,
};

interface IPrimaryNavBarProps {
  showHelpButton: boolean;
  showGetQuoteButton?: boolean;
  showCustomer?: boolean;
  isMobileNavOpen: boolean;
  toggleNav: () => void;
}

const PrimaryNavBar = ({
  showHelpButton,
  showGetQuoteButton,
  showCustomer,
  isMobileNavOpen,
  toggleNav,
}: IPrimaryNavBarProps): JSX.Element => {
  const { locale, formatMessage } = useIntl();
  const { pathname } = useLocation();
  const rentalcoverLogoHref = pathname.includes('/account/profile') ? `/${locale}/account` : `/${locale}/`;
  const customer = useAppSelector(selectCurrentCustomer);

  const renderCustomerButton = () => (
    <StyledCustomerButton>
      {customer ? <CustomerDropdown customer={customer} /> : <StyledLoading $height="1.5rem" />}
    </StyledCustomerButton>
  );

  return (
    <StyledNav id="NavBar">
      <Container>
        <StyledContainer>
          <Brand {...brand} href={rentalcoverLogoHref} />
          <div>
            <Media lessThan="lg">
              <StyledNavBarToggler $active={isMobileNavOpen} onClick={toggleNav} data-test-id="NavBar-toggler" />
            </Media>
            <Media greaterThanOrEqual="lg">
              <StyledNavButtonGroups>
                {showHelpButton && <HelpButton />}
                <LocaleButton />
                {showCustomer && renderCustomerButton()}
                {showGetQuoteButton && (
                  <Button tag="a" href={`/${locale}/`} className="get-quote" buttonType="primary">
                    {formatMessage(rentalcoverSharedMessages.getQuoteButton)}
                  </Button>
                )}
              </StyledNavButtonGroups>
            </Media>
          </div>
        </StyledContainer>
      </Container>
    </StyledNav>
  );
};

export default PrimaryNavBar;
