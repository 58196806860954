export const fontFamilySansSerif = `brandon-grotesque, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  Ubuntu, Cantarell, 'Helvetica Neue', Arial, 'Kanit', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 
  'Segoe UI Symbol', 'Noto Color Emoji'`;
export const fontFamilySerif = "bitter, Georgia, Times, 'Times New Roman', 'Kanit', serif";
export const fontFamilyMonospace = `SFMono-Regular, Menlo, Monaco, Consolas, 'Roboto Mono', 'Oxygen Mono',
  'Ubuntu Mono', 'DejaVu Sans Mono', 'Liberation Mono', 'Courier New', 'Kanit', monospace`;
export const fontFamilyHelvetica = `'Helvetica Neue', Arial, 'Kanit', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 
    'Segoe UI Symbol', 'Noto Color Emoji'`;

export const fontFamilies = {
  base: fontFamilySansSerif,
  headings: fontFamilySansSerif,
  serif: fontFamilySerif,
  sanSerif: fontFamilySansSerif,
  monospace: fontFamilyMonospace,
  // helvetica: fontFamilyHelvetica,
};
