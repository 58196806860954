import api, { LOCALE_HOST } from '../../../helpers/api';
import { xcmsAPI } from '.';

const messagesAPI = xcmsAPI.injectEndpoints({
  endpoints: (builder) => ({
    fetchMessages: builder.query<Record<string, string>, { locale: string; reqInit: RequestInit }>({
      async queryFn({ locale, reqInit }) {
        const searchParams = new URLSearchParams({ language: locale });
        try {
          const res = await api.get<Record<string, string>>(`${LOCALE_HOST}/?${searchParams}`, false, reqInit);
          return {
            data: res,
          };
        } catch (error: any) {
          return { error };
        }
      },
      serializeQueryArgs: ({ queryArgs: { locale } }) => ({ locale }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchMessagesQuery,
  util: { getRunningQueriesThunk },
} = messagesAPI;
export const { fetchMessages } = messagesAPI.endpoints;
