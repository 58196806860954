import React from 'react';
import { useIntl } from 'react-intl';
import { ICustomer } from '../../helpers/types';
import { getFullnameFromUser } from '../../helpers/auth';
import { formatCurrency } from '../../helpers/i18n';
import { StyledCustomerDetails } from './styledCustomerDetails';

interface ICustomerDetailsProps {
  customer: ICustomer;
}

const CustomerDetails = ({ customer }: ICustomerDetailsProps) => {
  const intl = useIntl();
  const fullName = getFullnameFromUser(customer) || '';
  const { email } = customer;

  return (
    <StyledCustomerDetails className="customer-data">
      <span className="customer-name">{fullName}</span>
      <span className="customer-email">{email}</span>
      {customer.credits && customer.credits.amount > 0 && (
        <span className="customer-credit">
          {formatCurrency(intl, customer.credits.amount, customer.credits.currency)}
        </span>
      )}
    </StyledCustomerDetails>
  );
};

export default CustomerDetails;
