import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICustomer } from '@whitelabel/rentalcover-shared/helpers/types';
import type { RootState } from '..';

interface ICustomerSliceState {
  data?: ICustomer;
}

const initialState: ICustomerSliceState = { data: undefined };

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomer: (state: ICustomerSliceState, { payload }: PayloadAction<{ customer?: ICustomer }>) => {
      state.data = payload.customer;
    },
    clearCustomer: (state: ICustomerSliceState) => {
      state.data = undefined;
    },
  },
});

export const { setCustomer, clearCustomer } = slice.actions;

export const customerReducer = slice.reducer;

export const selectCurrentCustomer = (state: RootState) => state && state.customer.data;
