import styled, { css } from 'styled-components';
import { fontStyle, fontWeight, lineHeight, resetLinkStyle } from '@whitelabel/helpers/style/type';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

// This is just a placeholder file. It is better not to consider to review this file
export const StyledHeading = styled.div`
  width: 50%;
  line-height: ${lineHeight('headings')};

  ${mediaBreakpointUp('sm')`
    width: 100%;
  `}
`;

export const StyledLocaleListItem = styled.li`
  ${fontStyle('contentMenu')}
  margin-bottom: 1rem;
  padding: 0;
  list-style-type: none;
`;

export const StyledLocaleLink = styled.a<{ $active: boolean }>`
  ${resetLinkStyle}
  font-weight: ${fontWeight('normal')};
  text-transform: capitalize;
  background: transparent;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;

  ${({ $active }) =>
    $active &&
    css`
      :not([href]):not([tabindex]) {
        color: ${themeColor('success')};
      }
    `}
  :not([href]):not([tabindex]):hover {
    color: ${themeColor('success')};
    background: transparent;
  }
`;
