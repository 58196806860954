import { ITranslation } from './types';

/**
 * Translation coming from legacy contains double curly braces replacer.
 * This is not handled properly by react-intl hence needs to be replaced by single curly braces.
 * This is done by using lazy capture group, capturing globally in case of multiple instances.
 */
const formatTranslation = (translation: string): string => translation.replace(/{{(.*?)}}/g, '{$1}');

/**
 *
 * @param translations Array of translations
 * @param shouldFormat Should the translations be formatted (due to double curly braces)
 * @returns
 */
export const getModifiedTranslations = (translations: ITranslation[], shouldFormat = false) =>
  translations.reduce<Record<string, string>>((accumulator, value) => {
    accumulator[`${value.category}.${value.message}`] = shouldFormat
      ? formatTranslation(value.translation)
      : value.translation;
    return accumulator;
  }, {});
