import * as FullStory from '@fullstory/browser';
import { IBWClaimDetailsSummaryTracking, IBWDocUploaderEvent, IBWDocUploaderMetrics, IBWIdentify } from './types';

export const bwClaimDetailsSummaryTracking = ({
  claimReference,
  partnerID,
  partnerName,
  subsidiaryID,
  subsidiaryName,
}: IBWClaimDetailsSummaryTracking): void => {
  if (window.isHeadless || !window.bwtag) return;

  window.bwtag('record', 'claim_summary', {
    claimReference,
    claim_cla: claimReference,
    partner: partnerName,
    partnerID,
    partnerName,
    subsidiaryID,
    subsidiaryName,
    fsSession: FullStory.isInitialized() ? FullStory.getCurrentSessionURL(true) : 'FS Not Ready',
  });
};

export const bwIdentify = ({ id, email, firstName, lastName }: IBWIdentify): void => {
  if (window.isHeadless || !window.bwtag) return;

  window.bwtag('identify', id, {
    email,
    firstName,
    lastName,
  });
};

export const bwDocUploaderTrack = <T extends IBWDocUploaderEvent>(eventName: T, metrics: IBWDocUploaderMetrics<T>) => {
  if (window.isHeadless || !window.bwtag) return;

  metrics.ins = metrics.bookingReference;
  metrics.claim_cla = metrics.claimReference;

  window.bwtag('record', eventName, metrics);
};
