import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { ReactComponent as IconTick } from '@whitelabel/media/icons/flags/localeFlags/selected_flag.svg';
import { CURRENT_LOCALE, EN_LOCALE_TEXT, EN_US_LOCALE_TEXT } from '@whitelabel/rentalcover-shared/helpers/constants';
import messages from '@whitelabel/rentalcover-shared/helpers/messages/commonMsg';
import StyledModal from '@whitelabel/rentalcover-shared/styled/StyledModal';
import {
  StyledHeading,
  StyledLocaleLink,
  StyledLocaleListItem,
} from '@whitelabel/rentalcover-shared/styled/styledLocaleModal';
import { locales } from '@whitelabel/rentalcover-shared/helpers/site';
import FlagIcon from '@whitelabel/rentalcover-shared/components/FlagIcon';
import { useTypedRouter } from '../../helpers/hooks';
import { useAppSelector } from '../../store';
import { selectCurrentCustomer } from '../../store/slices/customer';
import { localePrefixRegex } from '../../helpers/constants';

interface ILocaleModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const LocaleModal = ({ isOpen, toggle }: ILocaleModalProps) => {
  const intl = useIntl();
  const { replace, locale, location } = useTypedRouter();
  const customer = useAppSelector(selectCurrentCustomer);

  // we support en and en-us so we don't want to show two 'English' in locale modal because 'en'/en'us' determined by country of residence
  const localesToShowInModal = Object.keys(locales)
    .filter((l) => l !== EN_US_LOCALE_TEXT)
    .map((key) => ({
      code: key,
      name: locales[key].name,
      nameLocal: locales[key].nameLocal,
      xcode: locales[key].xcode,
    }));

  const handleLocaleChange = (newLocale: string) => async () => {
    toggle();
    if (newLocale !== locale) {
      localStorage.setItem(CURRENT_LOCALE, newLocale);
      const localeToReplace = newLocale === 'en' && customer?.country.code === 'US' ? 'en-us' : newLocale;
      const newURL = location.pathname.replace(localePrefixRegex, `/${localeToReplace}`);
      replace(newURL);
    }
  };

  const bodyContent = (
    <Row>
      {localesToShowInModal.map(({ code, name, nameLocal, xcode }) => {
        const localeIsActive = code === intl.locale || (intl.locale === EN_US_LOCALE_TEXT && code === EN_LOCALE_TEXT);

        return (
          <Col xs={6} sm={4} key={code}>
            <StyledLocaleListItem>
              <StyledLocaleLink
                $active={localeIsActive}
                data-test-id="LocaleModal-item"
                data-analytics={`locale${name}`}
                onClick={handleLocaleChange(code)}
              >
                {localeIsActive ? <IconTick /> : <FlagIcon locale={xcode} />}
                {nameLocal}
              </StyledLocaleLink>
            </StyledLocaleListItem>
          </Col>
        );
      })}
    </Row>
  );

  return (
    <StyledModal
      isOpen={isOpen}
      header={<StyledHeading>{intl.formatMessage(messages.selectYourLanguage)}</StyledHeading>}
      body={bodyContent}
      toggle={toggle}
      backdrop
      centered
    />
  );
};

export default LocaleModal;
