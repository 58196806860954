import React from 'react';
import {
  StyledDropdownItem,
  StyledDropdownItemIcon,
} from '@whitelabel/rentalcover-shared/styled/StyledCustomerDropdown';
import GhostButton from '../../GhostButton';

interface IDropdownItemProps {
  name: string;
  path: string;
  icon?: React.FunctionComponent<React.PropsWithChildren<unknown>>;
  dataAnalytics?: string;
}

const DropdownItem = ({ name, path, icon, dataAnalytics }: IDropdownItemProps): JSX.Element => (
  <StyledDropdownItem data-test-id="CustomerDropdown-item" data-analytics={dataAnalytics}>
    {icon && <StyledDropdownItemIcon as={icon} data-test-id="CustomerDropdown-itemIcon" />}
    <GhostButton href={path}>{name}</GhostButton>
  </StyledDropdownItem>
);

export default DropdownItem;
