import { UncapitalizeObjectKeys } from './types';

const uncapitalizeKeys = <T extends object>(obj: T): UncapitalizeObjectKeys<T> =>
  Object.fromEntries(Object.entries(obj).map(([key, val]) => [key.toLowerCase(), val])) as UncapitalizeObjectKeys<T>;

export const uncapitalizeKeysInArray = <T extends object>(array: T[]): UncapitalizeObjectKeys<T>[] =>
  array.map(uncapitalizeKeys);

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};
