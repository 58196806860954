import React, { ReactNode, useCallback, useMemo } from 'react';
import { SharedRouterContext } from '@whitelabel/cp-shared/helpers/routerContext';
import { useTypedRouter, useRegisterBrowserExitWarning } from '../../helpers/hooks';

export const SharedRouterProvider = ({ children }: { children: ReactNode }) => {
  const { query, locale, goBack: back, replace, push, listen } = useTypedRouter();

  const beforeLeave = useCallback(
    (callback: (url?: string) => void) => listen((location) => callback(location.pathname)),
    [listen],
  );

  const contextValue = useMemo(
    () => ({
      query,
      locale,
      back,
      push,
      replace,
      beforeLeave,
      useRegisterBrowserExitWarning,
    }),
    [query, locale, back, push, replace, beforeLeave],
  );

  return <SharedRouterContext.Provider value={contextValue}>{children}</SharedRouterContext.Provider>;
};

export default SharedRouterProvider;
