import styled, { css } from 'styled-components';
import { Container, NavbarToggler } from 'reactstrap';
import { color, gray } from '@whitelabel/helpers/style/colors';
import { getThemeProp } from '@whitelabel/helpers/style/get';
import { fontSize } from '@whitelabel/helpers/style/type';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { theme as rentalcoverTheme } from '../theme';

export const StyledNav = styled.nav`
  height: 4rem;
  min-height: 4rem;
  max-height: 4rem;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  background: ${color('white')};
  z-index: 1040;

  padding: 0;
  color: ${gray(600)};
`;

export const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StyledNavButtonGroups = styled.div`
  display: flex;
  column-gap: 2rem;
`;

const hamburgerIcon = (strokeColor: string) =>
  /* eslint-disable-next-line max-len */
  `url("data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='4' rx='2' fill='${strokeColor.replace(
    '#',
    '%23',
  )}'/%3E%3Crect y='8' width='24' height='4' rx='2' fill='${strokeColor.replace(
    '#',
    '%23',
  )}'/%3E%3Crect y='16' width='24' height='4' rx='2' fill='${strokeColor.replace('#', '%23')}'/%3E%3C/svg%3E%0A")`;

export const StyledNavBarToggler = styled(NavbarToggler)<{ $active: boolean }>`
  &.navbar-toggler {
    display: flex;
    text-align: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border-color: ${color('white')};
  }
  ${({ $active }) =>
    $active
      ? css`
          &.navbar-toggler {
            background-color: ${rentalcoverTheme.colors.dodgerBlue};
            border-color: ${rentalcoverTheme.colors.dodgerBlue};
          }
          .navbar-toggler-icon {
            background-image: ${hamburgerIcon(rentalcoverTheme.nav.togglerIconColor.active)};
          }
          &.navbar-light &&,
          &.navbar-dark && {
            .navbar-toggler-icon {
              background-image: ${hamburgerIcon(rentalcoverTheme.nav.togglerIconColor.active)};
            }
          }
        `
      : css`
          .navbar-toggler-icon {
            background-image: ${hamburgerIcon(rentalcoverTheme.nav.togglerIconColor.inactive)};
          }
          &.navbar-light &&,
          &.navbar-dark && {
            .navbar-toggler-icon {
              background-image: ${hamburgerIcon(rentalcoverTheme.nav.togglerIconColor.inactive)};
            }
          }
        `}

  padding: calc(0.625rem - ${getThemeProp('borderWidth')})
      calc(0.5rem - ${getThemeProp('borderWidth')});
  font-size: ${fontSize('base')};
  border-radius: ${borderRadius('base')};

  &:focus {
    box-shadow: none;
  }

  &.navbar-toggler-icon {
    display: block;
    width: 1.5rem;
    height: 1.25rem;
  }
`;

export const StyledCustomerButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
`;
