import React from 'react';
import { StyledSecondaryNavBar, StyledContainer } from '@whitelabel/rentalcover-shared/styled/StyledSecondaryNavBar';
import { ProtectionButton, ClaimDetailsButton } from '../PrimaryNavBar/NavButtons';

interface ISecondaryNavBarProps {
  claimReference?: string;
}

const SecondaryNavBar = ({ claimReference }: ISecondaryNavBarProps) => (
  <StyledSecondaryNavBar>
    <StyledContainer>
      {claimReference ? <ClaimDetailsButton claimReference={claimReference} /> : <ProtectionButton />}
    </StyledContainer>
  </StyledSecondaryNavBar>
);

export default SecondaryNavBar;
