import React from 'react';
import parseHtml, { DOMNode } from 'html-react-parser';
import { IntlShape } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { decodeHTML } from 'entities';
import Button from '@whitelabel/component-library/src/components/Button';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import { smoothScrollToAnchor } from '@whitelabel/helpers/url';
import supportedLanguages from '@whitelabel/helpers/supportedLanguages';
import { ICurrency } from './types/currencyTypes';

export const localizePathname = (pathname: string, locale: string) => {
  const pathnameArray = pathname.split('/');
  if (Object.keys(supportedLanguages).includes(pathnameArray[1])) {
    pathnameArray[1] = locale;
  } else {
    pathnameArray.splice(1, 0, locale);
  }
  return pathnameArray.join('/');
};

function formatAttributes(attribs: any) {
  let result = '';
  if (attribs) {
    result = Object.entries(attribs)
      .map(([name, value]) => `${name}="${value}"`)
      .join(' ');
  }
  return result;
}

function formatChildren(children: any) {
  let result = '';
  children.forEach((element: any) => {
    if (element.type === 'text') {
      result += element.data;
    } else if (element.type === 'tag') {
      result += `<${element.name} ${formatAttributes(element.attribs)}>${formatChildren(element.children)}</${
        element.name
      }>`;
    }
  });
  return result;
}

// TODO: use html-react-parser transform function
/* eslint-disable react/destructuring-assignment, react/display-name */
export const transformLink =
  (intl: IntlShape, ignoreLinkHref = false) =>
  (node: DOMNode) => {
    const history = useHistory();
    const setHash = (path: any) => {
      history.push({ hash: path });
      smoothScrollToAnchor(path)();
    };

    if (node.type === 'tag' && node.name === 'a') {
      if (!node.attribs.href && ignoreLinkHref) {
        // TODO: RC temporarily needs to render a tag without href while content will be fixed in backend
        return undefined;
      }

      if (!node.attribs.href) {
        const error = new Error('HTML a tag is missing a valid href attribute');
        captureExceptionWithFullStory(error);

        return undefined;
      }

      if (node.attribs.href.startsWith('/')) {
        const path = localizePathname(node.attribs.href, intl.locale);
        return (
          <Link key={path} to={path}>
            {parseHtml(formatChildren(node.children))}
          </Link>
        );
      }

      if (node.attribs.href.startsWith('?')) {
        const path = node.attribs.href;

        return (
          <Link key={path} to={path}>
            {parseHtml(formatChildren(node.children))}
          </Link>
        );
      }

      if (node.attribs.href.startsWith('#')) {
        const path = node.attribs.href;

        if (node.attribs['data-style'] === 'button') {
          return (
            <Button
              type="button"
              key={node.attribs.href}
              onClick={() => {
                setHash(node.attribs.href);
              }}
            >
              {parseHtml(formatChildren(node.children))}
            </Button>
          );
        }

        return (
          <Link
            key={path}
            to={path}
            onClick={() => {
              setHash(node.attribs.href);
            }}
          >
            {parseHtml(formatChildren(node.children))}
          </Link>
        );
      }
    }
    return undefined;
  };
/* eslint-enable */

export const formatCurrency = (intl: IntlShape, value: number | bigint, currency: ICurrency): string => {
  const { format, isZeroDecimal } = currency;

  const formattedValue = intl.formatNumber(
    value,
    isZeroDecimal
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  );

  const formattedCurrency = decodeHTML(format.replace(/%\.\df/, formattedValue));

  return formattedCurrency;
};
