export const localePrefixRegex = /\/[a-zA-Z_-]{2,}/i;
export const localePrefix = '/:locale([a-zA-Z_-]{2,})';
/**
 * We have different local standards between RC and XC.
 * We need to map the RC locale to the XC locale when requesting XC endpoints
 */
export const MAP_RC_LOCALE_TO_XC: Record<string, string> = {
  'zh-cn': 'zh-hans',
  'zh-hk': 'zh-hant',
};
