import { themeColors } from './colors';

const nav = {
  togglerIconColor: {
    inactive: themeColors.secondary,
    active: themeColors.primaryBg,
  },
};

export default nav;
