import styled from 'styled-components';
import HeroBlock from '@whitelabel/component-library/src/components/ContentBlock/HeroBlock';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { theme as rentalcoverTheme } from '../theme';

export const StyledHeroBlock = styled(HeroBlock)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 0;
  margin-top: 0;
  height: inherit;
  & .error-page-title span {
    background: ${themeColor('secondary')};
  }
  & > div {
    flex-grow: 1;
  }

  h1 {
    margin-bottom: 1.5rem;
  }

  span {
    background-color: ${rentalcoverTheme.colors.dodgerBlue};
    color: white;
    padding-inline: 0.5rem;
    margin-left: 0;
    ${mediaBreakpointUp('md')`
      margin-left: 0;
    `}
  }
  & .lead {
    font-size: 1.125rem;
    line-height: 1.125rem;
  }
  & div.col {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
  & a.btn > svg {
    color: ${themeColor('primary')};
  }

  .buttons-block {
    width: fit-content;
  }
`;
