import { camelCase, mapKeys } from 'lodash/fp';
import { ICustomer } from '@whitelabel/helpers/types';
import { ICustomer as IRCCustomer } from '@whitelabel/rentalcover-shared/helpers/types';
import { getCPAPIHost } from '../../helpers/api';
import { claimsPortalAPI } from '.';

export const customerAPI = claimsPortalAPI.injectEndpoints({
  endpoints: (builder) => ({
    checkCustomer: builder.mutation<ICustomer, { media: string }>({
      query: (body) => ({ url: `${getCPAPIHost()}/customers/lookup/?language=en`, method: 'POST', body }),
    }),
    getCustomerByID: builder.query<ICustomer, { id: string; locale: string }>({
      query: ({ id, locale }) => ({
        url: `${getCPAPIHost()}/customers/${id}/?language=${locale}`,
      }),
      transformResponse: (response: ICustomer) => mapKeys(camelCase)(response) as unknown as ICustomer,
    }),
    getRCCustomer: builder.query<IRCCustomer, void>({
      query: () => `${getCPAPIHost()}/auth/me`,
    }),
  }),
  overrideExisting: false,
});

export const { useCheckCustomerMutation, useGetCustomerByIDQuery, useGetRCCustomerQuery } = customerAPI;
