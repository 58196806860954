import styled from 'styled-components';
import Alert from '@whitelabel/component-library/src/components/Alert';
import { lineHeight, fontWeight } from '@whitelabel/helpers/style/type';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

const StyledAlert = styled(Alert)<{ $hideIcon: boolean }>`
  padding: 1rem;

  ${mediaBreakpointUp('sm')`
    padding: 1.5rem;
  `}

  h4 {
    margin-bottom: 0.5rem;
    line-height: ${lineHeight('headingsLg')};
    margin-inline-end: 0.5rem;
    margin-inline-start: 0.5rem;
    text-transform: uppercase;
    font-weight: ${fontWeight('bold')};
  }

  p {
    margin-inline-end: 0.5rem;
    margin-inline-start: -1.75rem;
  }
  ${({ $hideIcon }) =>
    $hideIcon &&
    `
      svg {
        display: none;
      }`}
`;

export default StyledAlert;
