import styled from 'styled-components';
import { Collapse } from 'reactstrap';
import { fontWeight } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';
import Button from '@whitelabel/component-library/src/components/Button';

export const StyledCollapseButton = styled(Button)`
  display: flex;
  flex-direction: unset;

  align-items: center;
  justify-content: flex-start;

  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  svg {
    color: ${gray(600)};
  }
`;

export const StyledCollapseText = styled.span`
  font-weight: ${fontWeight('black')};
  font-size: 0.625rem;
  line-height: 1rem;
  text-transform: uppercase;
  margin-inline-start: 0.75rem;

  color: ${gray(700)};
`;

export const StyledCollapse = styled(Collapse)`
  padding: 0;
`;
