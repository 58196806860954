import React from 'react';
import {
  StyledNavIcon,
  StyledNavButton,
  StyledNavIconWrapper,
} from '@whitelabel/rentalcover-shared/styled/StyledNavButton';
import GhostButton from '../../GhostButton';

interface INavItemProps {
  icon?: React.FunctionComponent<React.PropsWithChildren<unknown>>;
  name: string;
  path?: string;
  onClick?: () => void;
  className?: string;
}

const NavItem = ({ icon, name, className, path, onClick }: INavItemProps) => (
  <StyledNavButton className={className} buttonType="tertiary" onClick={onClick}>
    {icon && (
      <StyledNavIconWrapper>
        <StyledNavIcon as={icon} />
      </StyledNavIconWrapper>
    )}
    {path ? <GhostButton href={path}>{name}</GhostButton> : name}
  </StyledNavButton>
);

export default NavItem;
