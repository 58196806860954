import { DependencyList, createContext, useContext } from 'react';
import { MessageDescriptor } from 'react-intl';

export interface SharedRouterContextProps<T = Partial<Record<string, string | string[]>>> {
  /**
   * The second arg of push/replace are different in Next.js and React Router
   * It has to be undefined to ensure compatibility
   */
  push: (url: string, unknown?: undefined, ...args: any[]) => void | Promise<boolean>;
  replace: (url: string, unknown?: undefined, ...args: any[]) => void | Promise<boolean>;
  back: () => void;
  locale?: string;
  query: T;
  /**
   * calls when user tries to leave the page
   * @returns cleanup function
   */
  beforeLeave?: (callback: (url?: string) => void) => () => void;
  useRegisterBrowserExitWarning?: (
    callback: () => { isEnabled: boolean; message?: MessageDescriptor },
    deps: DependencyList,
  ) => void;
}

export const SharedRouterContext = createContext<SharedRouterContextProps>({
  push: () => {},
  replace: () => {},
  back: () => {},
  query: {},
  locale: 'en',
  beforeLeave: () => () => {},
});

/**
 * This hook ensures compatibility of router methods with both Next.js and React Router.
 * It's meant to only be used in cp-shared components
 * Providers are defined in respective apps
 */
export const useSharedRouter = <T,>() => {
  const router = useContext(SharedRouterContext) as unknown as SharedRouterContextProps<T>;
  if (!router) {
    throw new Error('useSharedRouter must be used within a RouterProvider');
  }
  return router;
};
