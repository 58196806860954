import styled from 'styled-components';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { notDisabledAndActive, notDisabledAndFocus } from '../helpers/selectors';

export const StyledSpecialButton = styled.a`
  color: inherit;

  text-decoration: none !important;
  box-shadow: none !important;

  &.hover,
  &:hover,
  &${notDisabledAndActive}, &${notDisabledAndFocus} {
    color: ${themeColor('heading')};
  }
`;
