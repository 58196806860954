import styled from 'styled-components';
import { Col } from 'reactstrap';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { color, gray, themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import Divider from '@whitelabel/component-library/src/styled/StyledDivider';

const StyledContainerRowZeroMarginInline = `
  .container > .row {
    margin-inline: 0;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 1.5rem 0;

  border-color: #00327c;
`;

export const StyledFooter = styled.footer`
  width: 100%;
  flex-direction: column;
  color: ${color('black')};
  margin-top: 0;

  border-top: 1px solid ${gray(400)};
`;

export const StyledLinksWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;

  padding: 1rem;
  background-color: ${color('white')};

  ${mediaBreakpointUp('md')`
    padding: 1.25rem;
  `};

  ${mediaBreakpointUp('lg')`
      padding: 2rem;
  `};

  ${StyledContainerRowZeroMarginInline}
`;

export const StyledRichText = styled.div`
  ${fontStyle('disclaimer')}
  & > p {
    ${fontStyle('disclaimer')}
  }

  padding: 0;
`;

export const StyledMinimumCollapsibleTextWrapper = styled.footer`
  background-color: ${color('white')};
  color: ${gray(600)};

  padding: 1.5rem 2rem;
`;

export const StyledCollapsibleTextWrapper = styled.div`
  background-color: ${themeColor('bgLight')};
  color: ${gray(600)};

  padding: 1rem;

  ${mediaBreakpointUp('md')`
    padding: 1.25rem;
  `};

  ${mediaBreakpointUp('lg')`
    margin-bottom: 0;
    padding: 2rem;
  `};

  ${StyledContainerRowZeroMarginInline}
`;

export const StyledBrandWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;

  color: ${gray(600)};

  .copyright {
    ${fontStyle('disclaimer')};
  }

  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  ${mediaBreakpointUp('sm')`
    flex-direction: row;
    align-items: center;
  `}
`;

export const StyledLinkContainer = styled(Col)`
  margin-bottom: 1rem;

  .row > * {
    width: unset;
    padding: 0;
    margin-bottom: 0.5rem;
  }

  .footer-column-header {
    color: #002b52;
  }
`;

export const StyledNoDisclaimerFooter = styled.div`
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;

  .footer-copyright {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .footer-links {
    width: 100%;
    display: flex;
  }

  ${mediaBreakpointUp('md')`
    flex-wrap: nowrap;
    margin: 0;

    .footer-copyright {
      width: auto;
      margin-bottom: 0rem;
    }

    .footer-links {
      width: auto;
    }
  `}
`;

export const StyledVerticalDivider = styled.span`
  display: inline;
  height: 100%;
  width: 1px;
  border: 1px #e0e4e8 solid;
  margin: 0.75rem;
  align-self: center;
`;
