import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { createCPError } from '../../helpers/api';

// Capture API error to Sentry and FS
export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    // Create the actual error with our standards and log it to Sentry with FS
    const error = createCPError({
      status: action.payload.status,
      response: action.payload.data,
      responseHeaders: action?.meta?.baseQueryMeta?.response?.headers,
      url: action.meta.baseQueryMeta?.request?.url,
      endpointName: action.meta.arg.endpointName,
    });

    action.payload.data = error;
  }

  return next(action);
};
