import React from 'react';
import { useIntl } from 'react-intl';
import { StyledDivider, StyledLoading } from '@whitelabel/rentalcover-shared/styled';
import { profileLink, protectionLink, claimDetailsLink } from '@whitelabel/rentalcover-shared/helpers/navLinks';
import {
  StyledCollapse,
  StyledNavCustomer,
  StyledScrollableContainer,
} from '@whitelabel/rentalcover-shared/styled/StyledCollapsibleNav';
import CustomerDetails from '@whitelabel/rentalcover-shared/components/CustomerDetails';
import rentalcoverSharedMessages from '@whitelabel/rentalcover-shared/helpers/messages/commonMsg';
import { HelpButton, LocaleButton } from '../NavButtons';
import { useAppSelector } from '../../../../../store';
import { selectCurrentCustomer } from '../../../../../store/slices/customer';
import NavItem from '../NavItem';
import { useTypedRouter } from '../../../../../helpers/hooks';
import { StyledMainButton } from './styledCollapsibleNav';

interface ICollapsibleNavProps {
  isMobileNavOpen: boolean;
  claimReference?: string;
}

const CollapsibleNav = ({ isMobileNavOpen, claimReference }: ICollapsibleNavProps): JSX.Element => {
  const intl = useIntl();
  const { locale } = useTypedRouter();
  const customer = useAppSelector(selectCurrentCustomer);

  const loggedInSection = customer && (
    <StyledNavCustomer className="nav-customer">
      {!customer ? (
        <StyledLoading />
      ) : (
        <>
          <CustomerDetails customer={customer} />
          {claimReference ? (
            <NavItem {...claimDetailsLink(intl, claimReference)} />
          ) : (
            <>
              <NavItem {...protectionLink(intl)} />
              <NavItem {...profileLink(intl)} />
            </>
          )}
        </>
      )}
      <StyledDivider />
    </StyledNavCustomer>
  );

  return (
    <StyledCollapse $isOpen={isMobileNavOpen}>
      <StyledScrollableContainer>
        {loggedInSection}
        <div className="nav-common">
          <HelpButton />
          <LocaleButton />
        </div>
      </StyledScrollableContainer>
      <StyledMainButton tag="a" href={`/${locale}/`} className="get-quote" buttonType="primary">
        {intl.formatMessage(rentalcoverSharedMessages.getQuoteButton)}
      </StyledMainButton>
    </StyledCollapse>
  );
};

export default CollapsibleNav;
