import styled, { css } from 'styled-components';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import Button from '@whitelabel/component-library/src/components/Button';
import { gray, themeColor } from '@whitelabel/helpers/style/colors';

export const StyledNavIcon = styled.svg`
  display: block;
  margin-inline-end: 0.5rem;
`;

export const StyledNavButton = styled(Button)<{ $spaceBetween?: boolean }>`
  padding: 0;
  display: flex;
  align-items: center;
  width: auto;
  white-space: nowrap;
  color: ${themeColor('subheading')};

  // Force "Button" component not to set direction so that margin-inline on icons work as intended
  direction: unset;
  flex-direction: unset;

  ${({ $spaceBetween }) =>
    $spaceBetween &&
    css`
      justify-content: space-between;
      width: 100%;
    `}

  margin-bottom: 1rem;

  ${mediaBreakpointUp('lg')`
      margin-bottom: 0;
    `}
`;

const borderWidth = '0.05rem';

// margin-inline-start: -0.33rem is to center the icon for rounding
export const StyledNavIconWrapper = styled.div<{ $withRounding?: boolean }>`
  &&&& {
    margin-inline-end: 0.25rem;
  }
  width: 1rem;
  height: 1rem;
  overflow: hidden;

  html[dir='rtl'] & {
    direction: rtl;
  }

  ${({ $withRounding }) =>
    $withRounding &&
    css`
      border: ${borderWidth} solid ${gray(600)};
      border-radius: 50%;
      &&& > svg {
        margin-inline-start: calc(-0.33rem - ${borderWidth});
        margin-top: calc(-0.05rem - ${borderWidth});
      }
    `}

  > .flag-image {
    max-width: none;
    height: 1.2rem;
    margin-inline-start: -0.33rem;
    margin-top: -0.3rem;
  }
`;
