import { deleteCookieByName, getCookiesAsObject, isOnServer, setCookie } from '@whitelabel/helpers/utils';
import { ICustomer } from './types';
import { AUTH_TOKEN_STORAGE_NAME } from './constants';
import { isRCAffiliateLink } from './site';

export const getFullnameFromUser = (user?: ICustomer) => user && `${user.firstName} ${user.lastName}`;

/**
 * Set auth token on the cookie.
 * Except when the `document.location.ancestorOrigins` contains the RC affiliate link, where token is set inside sessionStorage
 */
export const setAuthToken = (token: string, hasExpireTime = true) => {
  if (isOnServer) return;

  if (isRCAffiliateLink()) {
    sessionStorage.setItem(AUTH_TOKEN_STORAGE_NAME, token);
    return;
  }

  // Token expires in 3 months
  const expiresInSeconds = 3 * 30 * 24 * 60 * 60;
  setCookie(
    AUTH_TOKEN_STORAGE_NAME,
    token,
    hasExpireTime ? new Date(new Date().getTime() + expiresInSeconds * 1000) : null,
  );
};
/**
 * Get auth token from cookie
 * Except when the `document.location.ancestorOrigins` contains the RC affiliate link, where token is obtained from sessionStorage
 * @description Do not use this to check if the user is loaded, use useAuthState().loading instead
 * @see useAuthState from redux/auth/authSelectors.ts
 */
export const getAuthToken = (): string | null => {
  if (isOnServer) return null;

  if (isRCAffiliateLink()) {
    return sessionStorage.getItem(AUTH_TOKEN_STORAGE_NAME);
  }

  return getCookiesAsObject()?.[AUTH_TOKEN_STORAGE_NAME] ?? null;
};

export const clearAuthToken = () => {
  if (isOnServer) return;

  if (isRCAffiliateLink()) {
    sessionStorage.removeItem(AUTH_TOKEN_STORAGE_NAME);
    return;
  }

  deleteCookieByName(AUTH_TOKEN_STORAGE_NAME);
};
