import React, { useEffect } from 'react';
import { StyledLoading } from '@whitelabel/rentalcover-shared/styled';
import { useGetRCCustomerQuery } from '@whitelabel/cp-shared/store/claimsPortalAPI/customer';
import { bwIdentify } from '@whitelabel/cp-shared/helpers/analytics';
import { initFeatureFlags } from '@whitelabel/cp-shared/helpers/featureFlag';
import { useAppDispatch } from '../../../store';
import { setCustomer } from '../../../store/slices/customer';
import ErrorPage from '../../../components/ErrorPage';
import { useGetRefreshTokenMutation, useGetTokenStatusQuery } from '../../../store/endpoints/auth';
import { useTypedRouter } from '../../../helpers/hooks';

export interface IProtectedLayoutProps {
  children: React.ReactNode;
}

const ProtectedLayout = ({ children }: IProtectedLayoutProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { location, locale } = useTypedRouter();
  const { currentData: tokenStatus } = useGetTokenStatusQuery(undefined, { refetchOnFocus: true });

  const {
    data: customer,
    isFetching: isFetchingRCCustomer,
    isError: isGetCustomerError,
  } = useGetRCCustomerQuery(undefined, { skip: tokenStatus !== 'valid' });

  const [getRCRefreshToken, { isLoading: isLoadingRefreshToken, isError: isGetRefreshTokenError }] =
    useGetRefreshTokenMutation();

  useEffect(() => {
    if (tokenStatus === 'none' || isGetRefreshTokenError) {
      window.location.replace(
        `/${locale}/auth/sign-in/?redirect=${encodeURIComponent(`${location.pathname}${location.search}`)}`,
      );
    } else if (tokenStatus === 'expired') {
      void getRCRefreshToken();
    } else if (customer) {
      dispatch(setCustomer({ customer }));
      bwIdentify(customer);
      initFeatureFlags(customer.id, locale);
    }
  }, [
    customer,
    dispatch,
    getRCRefreshToken,
    isGetRefreshTokenError,
    locale,
    location.pathname,
    location.search,
    tokenStatus,
  ]);

  if (isGetCustomerError) {
    return <ErrorPage />;
  }

  if (isFetchingRCCustomer || isLoadingRefreshToken || tokenStatus !== 'valid') {
    return <StyledLoading />;
  }

  return <>{children}</>;
};

export default ProtectedLayout;
